import { NotFoundView } from "../views";

export default [
  {
    path: "/:catchAll(.*)",
    component: NotFoundView,
    meta: {
      title: "Not found 404",
    },
  },
];
