import { OnboardingMainView, OnboardingConnectView } from "../views";

export default [
  {
    path: "/onboarding",
    component: OnboardingMainView,
    children: [
      {
        path: "",
        name: "onboarding.connect",
        component: OnboardingConnectView,
        meta: {
          auth: true,
          title: "Conect your socials",
        },
      },
    ],
  },
];
