import form from "@/forms/form";

// Login Form
export default {
  ...form,

  formName: "login-form",

  data: {
    email: null,
    password: null,
  },
};
