import { createRouter, createWebHistory } from "vue-router";
import Modules from "@/modules";
import { auth } from "./guards";

const routes = [];

Object.keys(Modules).forEach((module) => {
  if (
    Modules[module] &&
    Modules[module].router &&
    Modules[module].router.routes
  ) {
    routes.push(...Modules[module].router.routes);
  }
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return window.scrollTo({ top: 0, behavior: "smooth" });
    }
  },
  routes,
});

// Add guards before each route
router.beforeEach(auth);

export default router;
