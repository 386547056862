// import Vue from "vue";
import events from "./events";
import mitt from "mitt";
import * as Listeners from "./listeners";

export const emitter = mitt();

Object.keys(events).forEach((event) => {
  emitter.on(event, Listeners[event]);
});

export const EVENT = events;
