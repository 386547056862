import { FeedMainView } from "../views";

export default [
  {
    path: "/",
    component: FeedMainView,
    name: "feed.index",
    meta: {
      auth: true,
      title: "Feed",
    },
  },
];
