import {
  AuthMainView,
  AuthSplashView,
  AuthView,
  AuthRegisterView,
} from "../views";

export default [
  {
    path: "/hello",
    component: AuthMainView,
    // meta: { guests: true },
    children: [
      {
        path: "",
        name: "auth.splash",
        component: AuthSplashView,
        meta: {
          title: "Auth index",
        },
      },
      {
        path: "/login",
        name: "auth.login",
        component: AuthView,
        meta: {
          title: "Login index",
        },
      },
      {
        path: "/register",
        name: "auth.register",
        component: AuthRegisterView,
        meta: {
          title: "Login index",
        },
      },
    ],
  },
  {
    name: "auth.logout",
    path: "/logout",
    meta: { logout: true },
  },
];
