<template>
  <div class="container" fade-bottom>
    <center>
      <h1>Feed</h1>
    </center>
  </div>
</template>

<script>
export default {
  name: "FeedMainView",
};
</script>

<style lang="scss" scoped>
.container {
  height: calc(var(--vh, 1vh) * 100);
  min-height: 900px;
  @media (max-width: 600px) {
    min-height: 840px;
  }
}
</style>
