import storage from "@/storage";

export const USER_WAS_LOGGED_IN = (state, { token }) => {
  state.authenticated = true;
  state.token = token;
};

export const USER_REGISTERD = () => {};

export const USER_LOGGED_IN = (state, { token }) => {
  storage.setToken(token);

  state.authenticated = true;
  state.token = token;
};

export const TOKEN_WAS_UPDATED = (state, { token }) => {
  storage.setToken(token);
  state.token = token;
  state.setToUpdate = false;
};

export const USER_LOGGED_OUT = (state) => {
  storage.removeToken();
  state.authenticated = false;
  state.token = null;
};

export const TOKEN_TO_UPDATE_SET = (state) => {
  state.setToUpdate = true;
};
