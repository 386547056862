// import store from "./store";
import routes from "./routes";

export default {
  name: "Onboarding",
  vuex: {
    name: "onboarding",
    // store,
  },
  router: {
    routes,
  },
};
