import Auth from "./Auth";
import Onboarding from "./Onboarding";
import Feed from "./Feed";
import Errors from "./Errors";

export default {
  auth: Auth,
  onboarding: Onboarding,
  feed: Feed,
  errors: Errors,
};
