import Vuex from "vuex";
import Modules from "../modules";
// import plugins from "./plugins";

const modules = {};

Object.keys(Modules).forEach((module) => {
  if (Modules[module] && Modules[module].vuex && Modules[module].vuex.store) {
    modules[Modules[module].vuex.name] = Modules[module].vuex.store;
  }
});

const options = {
  modules,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  // plugins,
};

export default new Vuex.Store(options);
