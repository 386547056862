import http from "@/http";

export default {
  /**
   * Gets a token for the user
   * @param email
   * @param password
   */
  async login(email, password) {
    return http.post("login", {
      email,
      password,
    });
  },

  /**
   * Gets a token for the user
   * @param email
   * @param username
   * @param password
   * @param password_confirmation
   */
  async register(email, username, password, password_confirmation) {
    return http.post("register", {
      email,
      username,
      password,
      password_confirmation,
    });
  },

  /**
   *
   * @returns {Promise<*>}
   */
  async logout() {
    return http.delete("auth/tokens");
  },

  /**
   *
   * @param id
   * @param email
   * @returns {Promise<*>}
   */
  async resendPassword(id, email) {
    return http.post("auth/passwords", {
      id,
      email,
    });
  },

  /**
   *
   * @param id
   * @param email
   * @param password
   * @param passwordConfirmation
   * @returns {Promise<*>}
   */
  async resetPassword(id, email, password, passwordConfirmation) {
    return http.put("auth/passwords", {
      id,
      email,
      password,
      password_confirmation: passwordConfirmation,
    });
  },

  /**
   * Gets a token for the user
   */

  async refreshToken() {
    return http.put("auth/tokens");
  },
};
