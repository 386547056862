<template>
  <form @submit.prevent="submit" v-bind="form.getFormBindings()">
    <input
      type="text"
      placeholder="Email address"
      name="email"
      v-on="form.getFieldEventListeners('email')"
      autocorrect="off"
      autocapitalize="none"
      required
    />
    <input
      type="password"
      placeholder="Password"
      v-on="form.getFieldEventListeners('password')"
      required
    />
    <button>Login to Basket</button>

    <router-link class="text-link" :to="{ name: 'auth.register' }">
      Don't have an account yet? Sign up
    </router-link>
  </form>
</template>

<script>
export default {
  name: "LoginForm",

  data: () => ({
    showPassword: false,
  }),

  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    submit() {
      this.$emit("submitLogin", { form: this.form });
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  max-width: 320px;
  margin: 30px auto 24px auto;
  .text-link {
    width: 100%;
    margin-top: 24px;
    display: block;
    align-items: center;
    font-size: 14px;
    color: #869096;
  }
}
</style>
