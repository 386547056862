import { cloneDeep } from "lodash";

export const defaultState = {
  authenticated: false,
  token: null,
  setToUpdate: false,
};

const state = cloneDeep(defaultState);

export default state;
