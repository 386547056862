import storage from "@/storage";
import { emitter, EVENT } from "@/bus";
import config from "@/config";
import router from "@/router";

export const auth = async (to, from, next) => {
  // Do we pretend to logout?
  if (to.matched.some((record) => record.meta && record.meta.logout)) {
    emitter.emit(EVENT.USER_LOGGED_OUT);
    return;
  }

  if (!storage.hasToken()) {
    console.log("no token");
  }

  if (to.matched.some((record) => record.meta && record.meta.auth)) {
    if (!storage.hasToken()) {
      next({
        name: config.router.guards.paths.auth,
        replace: true,
      });
    }
  } else if (to.name == "auth.login" && storage.hasToken()) {
    router.push({ name: "onboarding.connect" });
  }
  next();
};
