import axios from "axios";
import config from "@/config";
import storage from "@/storage";
// import { EventBus, EVENT } from "@/bus";

export const client = {
  instance: axios.create({
    baseURL: config.base.api,
    // timeout: config.http.timeout,
  }),

  headers: {},

  getHeaders() {
    return this.headers;
  },

  setToken(token) {
    this.headers.Authorization = `Bearer ${token}`;
  },

  prepareRequest() {
    if (storage.hasToken()) {
      this.setToken(storage.getToken());
    }
  },

  get(uri, config = {}) {
    return this.request("get", uri, config);
  },

  put(uri, data, config = {}) {
    return this.request("put", uri, data, config);
  },

  post(uri, data, config = {}) {
    return this.request("post", uri, data, config);
  },

  delete(uri, config = {}) {
    return this.request("delete", uri, null, config);
  },

  request(method, url, data = null, config = {}) {
    this.prepareRequest();
    config = Object.assign(
      {
        url,
        method,
        data,
        headers: this.getHeaders(),
      },
      config
    );

    return this.instance
      .request(config)
      .then((response) => {
        const { data } = response;
        return Promise.resolve(data);
      })
      .catch((error) => {
        let code = null;
        if (error && error.response) {
          // This should log the error object but is just printing out the message
          console.log(error);
          let errors = {};
          code = error.response.status;
          let message = error.response.statusText;
          if (error.response.data) {
            message = error.response.data.message || message;
            errors = error.response.data.errors || errors;
            error = {
              code,
              message,
              errors,
            };
          }
        }

        switch (code) {
          case 401:
            // EventBus.$emit(EVENT.USER_LOGGED_OUT);
            break;
          default:
            return Promise.reject(error);
        }

        return Promise.reject(error);
      });
  },
};

export default client;
