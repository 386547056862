import ls from "local-storage";
import config from "@/config";

export default {
  storage: {
    instance: ls,
    name: config.storage.name,
    getName(name) {
      return `${this.name}/${name}`;
    },

    setItem(name, value) {
      return this.instance.set(this.getName(name), value);
    },

    getItem(name) {
      return this.instance.get(this.getName(name));
    },

    hasItem(name) {
      return !!this.instance.get(this.getName(name));
    },

    removeItem(name) {
      return this.instance.remove(this.getName(name));
    },
  },

  hasToken() {
    return this.storage.hasItem("auth/token");
  },

  setToken(token) {
    return this.storage.setItem("auth/token", token);
  },

  removeToken() {
    return this.storage.removeItem("auth/token");
  },

  getToken() {
    return this.storage.getItem("auth/token");
  },
};
