<template>
  <form @submit.prevent="submit" v-bind="form.getFormBindings()">
    <input
      type="text"
      placeholder="Email address"
      name="email"
      v-on="form.getFieldEventListeners('email')"
      autocorrect="off"
      autocapitalize="none"
      required
    />
    <input
      type="text"
      placeholder="Username"
      name="username"
      v-on="form.getFieldEventListeners('username')"
      autocorrect="off"
      autocapitalize="none"
      required
    />
    <input
      type="password"
      placeholder="Password"
      v-on="form.getFieldEventListeners('password')"
      required
    />
    <input
      type="password"
      placeholder="Password"
      v-on="form.getFieldEventListeners('password_confirmation')"
      required
    />
    <button>Create my account</button>

    <router-link class="text-link" :to="{ name: 'auth.login' }">
      Already have an account? Login
    </router-link>
  </form>
</template>

<script>
import { useToast } from "vue-toastification";

export default {
  name: "RegisterForm",

  data: () => ({
    showPassword: false,
  }),

  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    submit() {
      if (this.form.data.password != this.form.data.password_confirmation) {
        this.toast.error("Passwords do not match..");
      } else {
        this.$emit("submitRegister", { form: this.form });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  max-width: 320px;
  margin: 30px auto 24px auto;
  .text-link {
    width: 100%;
    margin-top: 24px;
    display: block;
    align-items: center;
    font-size: 14px;
    color: #869096;
  }
}
</style>
