import routes from "./routes";

export default {
  name: "Error",
  vuex: {
    name: "error",
    // store,
  },
  router: {
    routes,
  },
};
