import router from "@/router";
import storage from "@/storage";
import store from "@/store";
import config from "@/config";

/**
 * When a user was already logged in
 * @constructor
 */
export const USER_WAS_LOGGED_IN = () => {
  const token = storage.getToken();
  store.commit({ type: "auth/USER_WAS_LOGGED_IN", token }, { root: true });
};

/**
 * When a user logged out
 * @constructor
 */
export const USER_LOGGED_OUT = () => {
  store
    .dispatch({ type: "auth/LogoutUserFeature" }, { root: true })
    .then(() => {
      router.push({ name: config.router.guards.paths.auth });
      // client.stop();
      // client.clearStore();
      window.location = "/";
    });
};

/**
 * When we got the data from the user
 * @constructor
 */
export const USER_DATA_WAS_FETCHED = ({ id, email, name }) => {
  console.log(id + " " + email + " " + name);
  // Sentry.configureScope((scope) => {
  //   scope.setUser({ id, email, name });
  // });
};
