export default {
  env: process.env.NODE_ENV,

  base: {
    url: process.env.BASE_URL,
    api: process.env.VUE_APP_API_BASE_URL,
  },

  storage: {
    name: "Basket",
  },

  router: {
    guards: {
      paths: {
        auth: "auth.splash",
        onboarding: "onboarding.guests",
      },
    },
  },
};
