import form from "@/forms/form";

// Reigster Form
export default {
  ...form,

  formName: "register-form",

  data: {
    email: null,
    username: null,
    password: null,
    password_confirmation: null,
  },
};
