<template>
  <div class="container" fade-bottom>
    <OnboardingSplash>
      <div class="connect-buttons">
        <span @click="facebookConnectClick" class="btn-outline">
          <svg
            width="12"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            class="svg-inline--fa fa-facebook-f fa-w-10 fa-2x"
            data-icon="facebook-f"
            data-prefix="fab"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
            />
          </svg>
          Connect with Facebook
        </span>
        <span @click="instagramConnectClick" class="btn">
          <svg
            width="16"
            aria-hidden="true"
            focusable="false"
            data-prefix="fab"
            data-icon="instagram"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
              class=""
            ></path>
          </svg>
          Connect with Instagram
        </span>
        <router-link class="text-link" :to="{ name: 'feed.index' }">
          What do you get from connection?
        </router-link>
      </div>
    </OnboardingSplash>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import OnboardingSplash from "../components/OnboardingSplash";

export default {
  name: "OnboardingConnectView",
  components: { OnboardingSplash },
  computed: {},
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    facebookConnectClick() {
      this.toast.info("Facebook connect not available right now");
    },
    instagramConnectClick() {
      this.toast.info("Google connect not available right now");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: calc(var(--vh, 1vh) * 100);
  min-height: 900px;
  @media (max-width: 600px) {
    min-height: 840px;
  }
  .connect-buttons {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: calc(100% + 48px);
    .btn,
    .btn-outline {
      margin-bottom: 15px;
      min-width: 280px;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 18px;
        margin-right: 0;
        svg {
          margin-right: 0;
        }
      }
    }
    .text-link {
      width: 100%;
      margin-top: 10px;
      display: block;
      align-items: center;
      font-size: 14px;
      color: #869096;
    }
  }
}
</style>
