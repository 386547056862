// import store from "./store";
import routes from "./routes";

export default {
  name: "Feed",
  vuex: {
    name: "feed",
    // store,
  },
  router: {
    routes,
  },
};
