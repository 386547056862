import { createApp } from "vue";
import App from "./App.vue";
import "./service-workers";
import router from "./router";
import store from "./store";

// Sync store with router
import { sync } from "vuex-router-sync";
sync(store, router);

import Toast from "vue-toastification";

// Styles
import "./style/style.scss";

// Fix ios height
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);
window.addEventListener("resize", () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

// Create app
const app = createApp(App);

app.config.productionTip = false;
// app.config.performance = true;

app.use(store);
app.use(router);
app.use(Toast, {
  position: "bottom-center",
  timeout: 4400,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: false,
  icon: false,
  rtl: false,
  transition: "fade",
  maxToasts: 6,
  newestOnTop: true,
});

app.mount("#app");
