import _ from "lodash";

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export default {
  originalData: {},

  isForceDirty: false,

  dirty: {},

  data: {},

  errors: {},

  loading: false,

  formName: null,

  setFormName(name) {
    this.formName = name;
  },

  getFormName() {
    return this.formName;
  },

  getFormId() {
    const name = this.getFormName();
    this.idCounter = 0;
    const $uuid = this.generateUUID(name);
    this.idCounter = 0;

    return $uuid;
  },

  isDisabled(field = null) {
    if (field) {
      if (
        typeof this[`${field}IsDisabled`] !== "undefined" &&
        typeof this[`${field}IsDisabled`] === "function"
      ) {
        return this[`${field}IsDisabled`]();
      }
    }

    return this.isLoading();
  },

  isLoading() {
    return !!this.loading;
  },

  setLoading(trueOrFalse) {
    this.loading = trueOrFalse;
  },

  getData() {
    return _.cloneDeep(this.data);
  },

  setErrors(errors = {}) {
    this.errors = errors;
  },

  cleanErrors() {
    this.setErrors({});
  },

  clear() {
    this.originalData = _.cloneDeep(this.data);

    Object.keys(this.data).forEach((field) => {
      let value = null;

      switch (typeof this.data[field]) {
        case "object":
          if (this.data[field] === null) {
            value = null;
          } else {
            value = {};
          }
          break;

        case "string":
          value = "";
          break;

        case "boolean":
          value = this.data[field];
          break;

        case "number":
          if (this.data[field] % 1 === 0) {
            value = 0;
          }

          if (this.data[field] % 1 !== 0) {
            value = 0.0;
          }

          break;
        default:
          value = null;
      }

      if (Array.isArray(this.data[field])) {
        value = [];
      }
      this.data[field] = value;
    });
    this.dirty = {};
    this.cleanErrors();
  },

  hasErrors() {
    return !!(this.errors && this.errors.message);
  },

  getErrors() {
    if (this.hasErrors()) {
      return this.errors.message;
    }

    return null;
  },

  hasErrorFor(field) {
    return (
      !!(this.errors && this.errors.errors && this.errors.errors[field]) ||
      !!(this.errors && this.errors[field])
    );
  },

  getErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return this.errors.errors[field] || this.errors[field];
    }
    return [];
  },

  getFieldValue(field) {
    let data;
    const computedGetFunction = this.computeGetFunctionFor(field);
    if (
      typeof this[computedGetFunction] !== "undefined" &&
      typeof this[computedGetFunction] === "function"
    ) {
      data = this[computedGetFunction]();
    } else {
      data = this.data[field];
    }

    return data;
  },

  getFieldId(field) {
    // this.idCounter = 0
    const $uuid = this.generateUUID(field);
    // this.idCounter = 0

    return $uuid;
  },

  generateUUID(name) {
    const $formName = this.formName ? `${this.getFormName()}-` : "";
    let $uuid = name;
    const $el = document.getElementById(name);

    if ($el) {
      this.idCounter += 1;
      $uuid = `${$formName}${name}-${this.idCounter}`;
      this.generateUUID($uuid);
    }

    return $uuid;
  },

  setFieldValues(fields) {
    if (Object.keys(fields).length) {
      Object.keys(fields).forEach((name) => {
        this.setFieldValue(name, fields[name]);
      });
    }
  },

  setInitialFieldValues(fields) {
    if (Object.keys(fields).length) {
      Object.keys(fields).forEach((name) => {
        this.setFieldValue(name, fields[name], true);
      });
    }
  },

  setFieldValue(field, value, isInitial = false) {
    // console.log(field, value.data, value.srcElement.value);
    if (isInitial) {
      console.log("true");
    }
    this.data[field] = value.srcElement.value;
  },

  getFormBindings() {
    return {
      id: this.getFormId(),
    };
  },

  getFormDirty() {
    return this.isDirty();
  },

  getFieldBindings(field) {
    return {
      error: this.hasErrorFor(field),
      errorMessages: this.getErrorFor(field),
      value: this.getFieldValue(field),
      inputValue: this.getFieldValue(field),
      id: this.getFieldId(field),
      disabled: this.isDisabled(field),
    };
  },

  getFieldEventListeners(field) {
    return {
      input: (value) => this.setFieldValue(field, value),
      change: (value) => this.setFieldValue(field, value),
    };
  },

  computeGetFunctionFor(field) {
    return `get${capitalize(field)}`;
  },

  computeSetFunctionFor(field) {
    return `set${capitalize(field)}`;
  },

  save() {
    this.dirty = {};
    this.originalData = _.cloneDeep(this.data);
  },

  isDirty() {
    return (
      this.dirty &&
      Object.keys(this.dirty).length &&
      Object.values(this.dirty).includes(true)
    );
  },
};
