import router from "@/router";
import api from "@/api";
import { useToast } from "vue-toastification";

const toast = useToast();

export const LoginFeature = async ({ commit }, { form }) => {
  const { email, password } = form.getData();

  form.setLoading(true);

  await api.auth
    .login(email, password)
    .then((response) => {
      const acces_token = response.acces_token;
      commit({ type: "USER_LOGGED_IN", acces_token });

      toast.success(response.message);
      router.push({ name: "onboarding.connect" });

      form.cleanErrors();
      return Promise.resolve();
    })
    .catch((errors) => {
      form.setErrors(errors);
      console.error(errors);
      toast.error(errors.message);
    })
    .then(() => form.setLoading(false));
};

export const LogoutUserFeature = async ({ commit }) => {
  commit({ type: "USER_LOGGED_OUT" });
  api.auth
    .logout()
    .catch((error) => {
      console.log(error);
    })
    .then(() => {});
};

export const RegisterFeature = async ({ commit }, { form }) => {
  const { email, username, password, password_confirmation } = form.getData();

  commit({ type: "USER_REGISTERD" });

  form.setLoading(true);

  await api.auth
    .register(email, username, password, password_confirmation)
    .then((response) => {
      toast.success(response.message);
      router.push({ name: "auth.login" });

      form.cleanErrors();
      return Promise.resolve();
    })
    .catch((response) => {
      console.error(response);
      form.setErrors(response.errors);
      toast.error(response.message);
    })
    .then(() => form.setLoading(false));
};
