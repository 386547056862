<template>
  <div class="onboarding-splash">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 523 349"
    >
      <linearGradient
        id="PSgrad_0"
        x1="1.11022302e-14%"
        y1="0"
        x2="100%"
        y2="80%"
      >
        <stop stop-color="#1ABAE5" offset="0%"></stop>
        <stop stop-color="#D75BBA" offset="100%"></stop>
      </linearGradient>

      <path fill="url(#PSgrad_0)">
        <animate
          repeatCount="indefinite"
          attributeName="d"
          dur="5s"
          values="M55.6428727,217.647756 C41.292912,188.402286 19.6503483,189.228429 6.94710445,164.77459 C-11.8725161,128.589517 8.59382125,74.5597506 55.6428727,53.9061702 C101.515698,33.5830471 132.803317,62.9937456 214.903912,48.2883963 C277.243905,37.0528486 276.773414,17.0601827 342.642086,6.32032091 C370.871517,1.6939189 440.033623,-9.54162885 484.259731,17.2254114 C528.95633,44.3229089 523.545689,95.709017 521.898972,112.562339 C517.899803,150.234469 497.668711,154.695643 499.785918,189.228429 C502.13837,226.404874 526.133387,231.857419 521.193236,259.615831 C516.017841,288.696072 482.848259,321.576572 442.386075,327.029118 C390.632119,333.803492 373.22397,290.017902 313.236429,289.687444 C240.310399,289.356987 217.256364,354.126615 152.563919,348.67407 C111.866489,345.369497 76.8149458,315.958799 64.8174377,291.174502 C50.7027223,262.755175 71.6395501,250.197798 55.6428727,217.647756 Z;
            M21.6841264,233.782986 C-4.19282343,217.384581 -3.55124616,183.33837 6.28627198,160.849129 C16.5515083,137.422837 34.3018128,133.049929 70.657858,103.532801 C136.09874,50.7455553 120.059308,31.8483462 162.617267,13.5758381 C210.093985,-6.88312382 271.899262,-1.26081366 311.463193,11.0770336 C350.171689,23.2587056 345.03907,34.9718518 404.064179,62.9272273 C465.655597,92.2881804 483.619761,85.2602927 503.936374,106.500131 C537.298392,141.327219 517.623356,189.741556 513.346174,200.517651 C506.288824,218.009283 498.162179,237.843543 473.782242,250.493741 C438.923211,268.453899 410.693811,251.586968 369.846725,267.673022 C335.201552,281.260272 342.900479,298.439553 309.538461,319.523216 C260.992448,350.289747 173.096362,359.660264 138.665049,334.672218 C106.158467,311.089751 139.306626,269.547126 103.16444,248.931988 C76.2181944,233.782986 47.7749354,250.493741 21.6841264,233.782986 Z;
            M55.6428727,217.647756 C41.292912,188.402286 19.6503483,189.228429 6.94710445,164.77459 C-11.8725161,128.589517 8.59382125,74.5597506 55.6428727,53.9061702 C101.515698,33.5830471 132.803317,62.9937456 214.903912,48.2883963 C277.243905,37.0528486 276.773414,17.0601827 342.642086,6.32032091 C370.871517,1.6939189 440.033623,-9.54162885 484.259731,17.2254114 C528.95633,44.3229089 523.545689,95.709017 521.898972,112.562339 C517.899803,150.234469 497.668711,154.695643 499.785918,189.228429 C502.13837,226.404874 526.133387,231.857419 521.193236,259.615831 C516.017841,288.696072 482.848259,321.576572 442.386075,327.029118 C390.632119,333.803492 373.22397,290.017902 313.236429,289.687444 C240.310399,289.356987 217.256364,354.126615 152.563919,348.67407 C111.866489,345.369497 76.8149458,315.958799 64.8174377,291.174502 C50.7027223,262.755175 71.6395501,250.197798 55.6428727,217.647756 Z"
        />
      </path>
    </svg>

    <div class="onboarding-splash__inner">
      <h2>
        Link your socials so that<br />
        we can serve you better!
      </h2>
      <h3>Find your friends</h3>
    </div>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "OnboardingSplash",
};
</script>

<style lang="scss" scoped>
.onboarding-splash {
  width: 100vw;
  min-width: 440px;
  max-width: 820px;
  position: absolute;
  max-height: 840px;
  top: 0;
  margin-top: 128px;
  left: calc(50% + 0px);
  transform: translate(-50%, 0);
  > svg {
    width: 100%;
  }
  @media (max-width: 600px) {
    top: -78px;
  }

  &__inner {
    position: absolute;
    left: 0;
    top: calc(50% - 64px);
    width: calc(100%);
    text-align: center;
    @media (max-width: 600px) {
      top: calc(50% - 56px);
    }
    > svg {
      min-width: 200px;
      width: 30vw;
      max-width: 320px;
      line-height: 0;
    }
    h2 {
      font-size: 42px;
      font-weight: bold;
      line-height: 62px;
      @media (max-width: 600px) {
        font-size: 28px;
        font-weight: bold;
        line-height: 46px;
      }
    }
    h3 {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      margin-top: 8px;
      @media (max-width: 600px) {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        margin-top: 8px;
      }
    }
  }
}
</style>
